<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div class="tbl_opt">
          <span class="option_txt">조회기간</span>
          <button class="excel_btn" @click="exportExcel()">
            엑셀로 저장
          </button>
          <div>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="start_date"
                @change="setStartDate($event)"
              />
            </div>
            <p>~</p>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="end_date"
                @change="setEndDate($event)"
              />
            </div>
            <button
              class="btn_sub2"
              @click="
                fetch_user_report();
                fetch_user_action();
              "
            >
              검색
            </button>
          </div>
        </div>
      </div>

      <report-bar-chart
        class="report_bar_chart"
        :data="chartData.data"
        :options="chartData.options"
        :height="$screen.width > 1280 ? 120 : 140"
        :key="key"
      ></report-bar-chart>
    </div>
    <user-report-modal
      :listArray="modalData"
      v-if="isGraphModalOpen"
      @onclose="isGraphModalOpen = false"
    ></user-report-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import XLSX from 'xlsx';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import { yyyymmdd, yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';
import ReportBarChart from '@/layouts/components/charts/ReportBarChart';
import UserReportModal from '@/layouts/components/UserReportModal';

export default {
  mixins: [ModalMixin, SpinnerMixin],
  components: {
    ReportBarChart,
    UserReportModal,
  },
  data() {
    return {
      key: 0,
      screenWidth: this.screenWidth < 1280,
      modalData: null,
      isGraphModalOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      menuItems: 'getMenuItems',
      date: 'getDateFromUserActionPage',
      user_actions_src: 'getUserAction',
      users: 'getUsersTempFromUserPage',
      actionType: 'getUserActionType',
      start_date: 'getStartDateFromUserActionPage',
      end_date: 'getEndDateFromUserActionPage',
      user_report: 'getUserReportFromReport',
    }),

    groupBySession() {
      let group_report = this.lodash
        .clonedeep(this.user_report)
        .filter(
          x => this.users.find(y => y.id == x.user_id).account != 'developer@',
        );

      const obj = group_report.reduce(function(rv, x) {
        (rv[x['user_id']] = rv[x['user_id']] || []).push(x);
        return rv;
      }, {});

      let arr = [];
      for (let k in obj) {
        let session_sum = this.$decimalAdd(
          this.$decimalAdd(
            this.$decimalMul(
              obj[k]
                .map(x => Number(x.session.split(':')[0].substr(0, 2)))
                .reduce((a, b) => this.$decimalAdd(a, b), 0),
              60,
            ),
            obj[k]
              .map(x => Number(x.session.split(':')[1]))
              .reduce((a, b) => this.$decimalAdd(a, b), 0),
          ),
          Math.round(
            Number(
              this.$decimalDiv(
                obj[k]
                  .map(x => Number(x.session.split(':')[2].split('.')[0]))
                  .reduce((a, b) => this.$decimalAdd(a, b), 0),
                60,
              ),
            ),
          ),
        );
        let dateMap = obj[k].map(x => x.login_time.split(' ')[0]);
        let dateFilter = dateMap.filter((x, i) => x != dateMap[i + 1]);
        let session_avg = Math.round(
          this.$decimalDiv(session_sum, dateFilter.length),
        );
        arr.push({
          user_name: this.users.find(x => x.id == k).name,

          element: obj[k]
            .map(x => x.user_id)
            .reduce((a, b) => this.$decimalAdd(a, b), 0),

          session_sum,
          session_avg,
          user_id: this.users.find(x => x.id == k).id,
        });
      }
      return arr;
    },
    chartData() {
      let groupSession = this.groupBySession;
      let data = {
        labels: groupSession.map(x => x.user_name),
        datasets: [
          {
            label: '누적사용(분)',
            data: groupSession.map(x => x.session_sum),
            categoryPercentage: 0.4,
            barPercentage: 0.9,
            borderColor: '#004a89',
            pointBackgroundColor: '#004a89',
            pointRadius: 4,
            lineTension: 0,
            yAxisID: 'y-axis-left',
            fill: false,
            type: 'line',
          },
          {
            label: '일 평균사용(분)',
            data: groupSession.map(x => x.session_avg),
            categoryPercentage: 0.4,
            barPercentage: 0.9,
            barThickness: 80,
            backgroundColor: '#ef6262',
            pointBackgroundColor: '#ef6262',
            yAxisID: 'y-axis-right',
            fill: false,
          },
        ],
      };

      return { data: data, options: this.getOption() };
    },
    user_actions() {
      return this.user_actions_src.filter(
        x => this.users.find(y => y.id == x.user_id).account != 'developer@',
      );
    },
  },
  methods: {
    getOption() {
      let tooltipEnable = true;
      let fontSize = this.$screen.width > 1280 ? 14 : 10;
      let options = {
        legend: {
          responsive: true,
          position: 'top',
          align: 'middle',
          display: true,
          labels: {
            boxWidth: 12,
          },
        },
        onClick: (mouse, evt) => {
          if (evt[0]) {
            let userId = this.groupBySession.find((x, y) => y == evt[0]._index)
              .user_id;
            const filteredAction = this.lodash
              .clonedeep(this.user_actions)
              .filter(x => x.user_id == userId);
            this.modalData = filteredAction;
            this.isGraphModalOpen = true;
          }
        },

        scales: {
          xAxes: [
            {
              ticks: {
                fontSize,
              },
            },
          ],
          yAxes: [
            {
              stacked: false,
              id: 'y-axis-left',
              ticks: {
                max: Math.max.apply(
                  null,
                  this.lodash
                    .clonedeep(this.groupBySession)
                    .map(x => Math.ceil(x.session_sum + x.session_avg)),
                ),
                beginAtZero: true,
                fontSize,
                callback: value => {
                  return this.$makeComma(value);
                },
              },
            },
            {
              stacked: false,
              id: 'y-axis-right',
              position: 'right',
              ticks: {
                fontSize,
                beginAtZero: true,
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            color: '#ffffff',
          },
        },
        elements: {
          point: {
            pointStyle: 'circle',
            pointColor: '#004a89',
            pointBackgroundColor: '#004a89',
          },
        },
        hover: {
          mode: 'nearest',
          intersect: true,
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          custom: function(tooltip) {
            if (!tooltip) return;
            tooltip.displayColors = false;
          },
          enabled: tooltipEnable,
          callbacks: {
            label: tooltipItems => {
              if (tooltipItems.datasetIndex === 0) {
                let multistringText = [];
                let sessionFilter = this.lodash
                  .clonedeep(this.groupBySession)
                  .filter(x => x.user_name == tooltipItems.label);
                multistringText.push(
                  `[누적사용] : ${sessionFilter.map(x => x.session_sum)}(분)`,

                  `[일 평균사용] : ${sessionFilter.map(
                    x => x.session_avg,
                  )}(분)`,
                );
                return multistringText;
              }
            },
          },
        },
      };
      return options;
    },

    setStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.end_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최후날짜(${this.end_date}) 이후의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.start_date;
        } else {
          this.$store.commit('setStartDateToUserActionPage', e.target.value);
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit(
          'setStartDateToUserActionPage',
          yyyymmdd(new Date()),
        );
      }
    },
    setEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.start_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최초날짜(${this.start_date}) 이전의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.end_date;
        } else {
          this.$store.commit('setEndDateToUserActionPage', e.target.value);
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setEndDateToUserActionPage', yyyymmdd(new Date()));
      }
    },
    getAccountFromUserId(id) {
      return this.users.find(x => x.id == id).name || '';
    },
    getTopMenuNameFromMenuId(id) {
      return this.menuItems.find(x => x.sub_menu.find(y => y.id == id)).name;
    },
    getSubMenuNameFromMenuId(id) {
      return this.menuItems
        .find(x => x.sub_menu.find(y => y.id == id))
        .sub_menu.find(x => x.id == id).name;
    },
    getActionNameFromTypeId(id) {
      return this.actionType.find(x => x.id == id).name;
    },

    async fetch_user_report() {
      try {
        this.showSpinner();
        //사용자 리스트가 없으면
        if (this.users.length == 0) {
          await this.fetch_user_list();
          console.log(this.users);
        }
        await this.$store
          .dispatch('FETCH_USER_REPORT', this.date)
          .then(() => {
            this.key++;
          })
          .catch(error => {
            console.log(error);
            this.openOneButtonModal(
              '오류',
              '사용현황 데이터를 불러오는 중 오류가 발생. 다시 로그인하여주십시오.',
            );
          });
        this.hideSpinner();
      } catch (error) {
        console.log(error);
        this.hideSpinner();
      }
    },
    async fetch_user_action() {
      try {
        this.showSpinner();
        if (this.users.length == 0) {
          await this.fetch_user_list();
        }
        await this.$store
          .dispatch('FETCH_USER_ACTION', this.date)
          .then(() => {})
          .catch(error => {
            console.log(error);
            this.openOneButtonModal(
              '오류',
              '사용현황 데이터를 불러오는 중 오류가 발생. 다시 로그인하여주십시오.',
            );
          });
        this.hideSpinner();
      } catch (error) {
        console.log(error);
        this.hideSpinner();
      }
    },
    async fetch_user_list() {
      try {
        this.showSpinner();
        this.$store
          .dispatch('FETCH_USER_LIST')
          .then(() => {})
          .catch(() => {
            this.openOneButtonModal(
              '불러오기 오류',
              '사용자 정보를 불러오는 중 오류발생. 다시 로그인하여주십시오.',
            );
          });
        this.hideSpinner();
      } catch (error) {
        this.openOneButtonModal(
          '불러오기 오류',
          '사용자 정보를 불러오는 중 오류발생. 다시 로그인하여주십시오.',
        );
        this.hideSpinner();
      }
    },
    async fetch_menu_items() {
      this.$store;
    },
    exportExcel() {
      let userReport = [];
      let userReportList = [];

      const date = this.start_date + ' ~ ' + this.end_date;

      const merge = [
        { s: { r: 0, c: 1 }, e: { r: 0, c: 4 } },
        { s: { r: 1, c: 1 }, e: { r: 1, c: 4 } },
      ];

      let standardInfo = [
        ['기준시간', yyyy년MM월dd일HH시mm분ss초(new Date())],
        ['조회기간', date],
        [],
        [],
        [],
      ];

      userReport = userReport.concat(standardInfo);
      userReportList = userReportList.concat(standardInfo);

      userReport.push([
        '조회일자',
        '사용자명',
        '일 평균사용(분)',
        '누적사용(분)',
      ]);
      this.groupBySession.forEach(el => {
        userReport.push([
          date, //조회기간
          el.user_name, //아이디
          el.session_avg + '(분)', //평균사용
          el.session_sum + '(분)', //누적사용
        ]);
      });
      userReportList.push([
        '이름',
        '대 메뉴',
        '서브 메뉴',
        '이용 가능',
        '일자',
      ]);
      this.user_actions.forEach(el => {
        userReportList.push([
          this.getAccountFromUserId(el.user_id), //아이디
          el.menu_group_id != null
            ? this.getTopMenuNameFromMenuId(el.menu_group_id)
            : '-', //대메뉴
          el.menu_group_id != null
            ? this.getSubMenuNameFromMenuId(el.menu_group_id)
            : '-', //서브메뉴
          this.getActionNameFromTypeId(el.user_action_type_id), //이용가능
          el.create_time.substr(0, 16), //일자
        ]);
      });

      let wb = XLSX.utils.book_new();
      let dataUserReport = XLSX.utils.json_to_sheet(userReport, {
        skipHeader: true,
      });
      let dataUserReportList = XLSX.utils.json_to_sheet(userReportList, {
        skipHeader: true,
      });

      dataUserReport['!merges'] = dataUserReportList['!merges'] = merge;

      XLSX.utils.book_append_sheet(wb, dataUserReport, '사용자 이용현황');
      XLSX.utils.book_append_sheet(
        wb,
        dataUserReportList,
        '전체 사용자 이용현황 목록',
      );

      let title = '사용자 이용현황(' + date + ').xls';
      XLSX.writeFile(wb, title);
    },
  },
  async created() {
    if (this.start_date == undefined) {
      var date = new Date();
      this.$store.commit('setEndDateToUserActionPage', yyyymmdd(date));
      var date2 = new Date(date.setDate(date.getDate() - 7));
      this.$store.commit('setStartDateToUserActionPage', yyyymmdd(date2));
    }
    if (this.user_report.length == 0) {
      await this.fetch_user_report();
    }

    if (this.user_actions_src.length == 0) {
      await this.fetch_user_action();
    }
  },
};
</script>

<style scoped></style>
